"use client";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from "@civic/ui";
import { AlertCircle, CheckCircle } from "lucide-react";
import { useSignMessage } from "wagmi";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useState } from "react";

const formSchema = z.object({
  message: z.string(),
});

const Sign = () => {
  const { data, error, signMessage } = useSignMessage();
  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    setLoading(true);

    signMessage(
      { message: values.message },
      {
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex w-full">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        placeholder="Add message to sign"
                        endSlot={
                          <Button size="xs" disabled={isLoading}>
                            Sign
                          </Button>
                        }
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>

      {data ? (
        <Alert>
          <CheckCircle className="h-4 w-4" />
          <AlertTitle>Message Signed</AlertTitle>
          <AlertDescription className="break-words">{data}</AlertDescription>
        </Alert>
      ) : null}

      {error ? (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="break-words">
            {error.message}
          </AlertDescription>
        </Alert>
      ) : null}
    </div>
  );
};

export { Sign };
