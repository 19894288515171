import Link from "next/link";
import Image from "next/image";
import logoBlack from "@/assets/civic-logo-black.svg";
import logoWhite from "@/assets/civic-logo-white.svg";
import logoNeutral from "@/assets/civic-logo-neutral.svg";

const Logo = ({
  className,
  width = 120,
  height = 32,
  color = "black",
}: {
  className?: string;
  width?: number;
  height?: number;
  color?: "black" | "white" | "neutral";
}) => {
  return (
    <Link href="/">
      {color === "black" && (
        <Image
          src={logoBlack}
          alt="Civic"
          width={width}
          height={height}
          className={className}
        />
      )}
      {color === "white" && (
        <Image
          src={logoWhite}
          alt="Civic"
          width={width}
          height={height}
          className={className}
        />
      )}
      {color === "neutral" && (
        <Image
          src={logoNeutral}
          alt="Civic"
          width={width}
          height={height}
          className={className}
        />
      )}
    </Link>
  );
};

export { Logo };
