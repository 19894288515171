"use client";

import { formatBalance } from "@/lib/utils";


interface BalanceProps {
  value: bigint | undefined;
}

const Balance = ({ value }: BalanceProps) => {
  const formattedBalance = formatBalance(value);

  return (
    <div className="flex items-center justify-center p-2">
      <span className="sm:text-md text-nowrap text-lg font-bold">
        {formattedBalance} ETH
      </span>
    </div>
  );
};

export { Balance };
