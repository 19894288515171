"use client";

import { useEffect, useState, useTransition } from "react";
import Image from "next/image";
import { airdrop, recipientIsEligibleForAirdrop } from "@/actions/airdrop";
import { Button } from "@/components/ui/button";
import { Ellipsis } from "lucide-react";
import { userHasWallet } from "@civic/auth-web3";
import { useUser } from "@civic/auth-web3/react";
import airdropLogo from "@/assets/airdrop.svg";

interface AirdropProps {
  onComplete: () => void;
}

export function Airdrop({ onComplete }: AirdropProps) {
  const [isPending, startTransition] = useTransition();
  const [canAirdrop, setCanAirdrop] = useState(false);
  const userContext = useUser(); // the {} is a hack until useUser is fixed not to return null
  // const { walletAddress } = userContext;
  const [result, setResult] = useState<{
    success: boolean;
    message: string;
  } | null>(null);

  useEffect(() => {
    if (userHasWallet(userContext)) {
      recipientIsEligibleForAirdrop(
        userContext.walletAddress as `0x${string}`,
      ).then(setCanAirdrop);
    }
  }, [setCanAirdrop, userContext, result]);

  useEffect(() => {
    if (result) {
      onComplete();
    }
  }, [result]);

  const handleClick = () => {
    setResult(null);
    startTransition(async () => {
      if (!userHasWallet(userContext)) return;

      const { error, hash } = await airdrop(
        userContext.walletAddress as `0x${string}`,
      );
      setResult({ success: !!hash, message: error || "Airdrop done" });
    });
  };

  return (
    <div className="relative flex flex-col items-center justify-center">
      {isPending && (
        <div className="absolute inset-0 top-1/2 flex items-center justify-center rounded-full bg-background/50">
          <Ellipsis
            color="#7C75FF"
            strokeWidth={4}
            className="stroke-black-600 h-15 w-5 animate-bounce"
          />
        </div>
      )}
      <Button
        onClick={handleClick}
        disabled={isPending || !canAirdrop}
        className="rounded-full bg-transparent p-2 shadow-none hover:bg-transparent"
      >
        <Image
          src={airdropLogo}
          alt="Airdrop"
          width={24}
          height={24}
          className="bg-transparent"
        />

        <span className="sr-only">Initiate Airdrop</span>
      </Button>
      {/* 
      TODO: handle error case
      {result && (
        <p className={result.success ? "text-green-600" : "text-red-600"}>
          {result.message}
        </p>
      )} */}
    </div>
  );
}
