import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatEther } from "viem";

const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

const formatBalance = (balance: bigint | undefined) => {
  if (!balance) return 0.0.toFixed(5);
  return Number.parseFloat(formatEther(balance)).toFixed(5);
};

const valueIsDefined = <K, V>(entry: [K, V | undefined]): entry is [K, V] =>
  entry[1] !== undefined;

const trimUndefinedValues = (
  map: Record<string, string | undefined>,
): Record<string, string> =>
  Object.fromEntries(Object.entries(map).filter(valueIsDefined));

export { cn, formatBalance, trimUndefinedValues };
