"use client";
import { useAccount, useConnect } from "wagmi";
import { TurnkeyConnector } from "../lib/turnkey/TurnkeyConnector.js";
import { useEffect, useState } from "react";
import { userHasWallet } from "../lib/walletUtils.js";
import { useUser } from "../components/Web3UserProvider.js";
import { isReady } from "../lib/wagmiUtils.js";

const useAutoConnect = (
  { autoConnectEmbeddedWallet, autoCreateWallet } = {
    autoConnectEmbeddedWallet: true,
    autoCreateWallet: true,
  },
) => {
  const { status } = useConnect();
  const userContext = useUser();
  const { isConnected } = useAccount();
  const { connectors } = useConnect();
  const [walletCreationInProgress, setWalletCreationInProgress] =
    useState<boolean>(false);
  const [connectionInProgress, setConnectionInProgress] =
    useState<boolean>(false);

  const connector = connectors.find(
    (connector) => connector.id === TurnkeyConnector.type,
  );

  useEffect(() => {
    if (isConnected) {
      setWalletCreationInProgress(false);
      setConnectionInProgress(false);
    }
  }, [isConnected]);

  useEffect(() => {
    if (!autoConnectEmbeddedWallet) return;

    const onConnectionChange = () => {
      if (
        connector &&
        isReady(connector) &&
        !isConnected &&
        !connectionInProgress
      ) {
        setConnectionInProgress(true);
        connector.connect();
      }
    };

    // register the connection change listener
    connector?.emitter.on("change", onConnectionChange);

    // deregister the connection change listener
    return () => {
      connector?.emitter.off("change", onConnectionChange);
    };
  }, []);

  useEffect(() => {
    if (
      !autoConnectEmbeddedWallet ||
      !autoCreateWallet ||
      walletCreationInProgress
    ) {
      return;
    }
    if (userContext.user && !userHasWallet(userContext)) {
      userContext.createWallet();
      setWalletCreationInProgress(true);
      return;
    }
  }, [userContext.user, status, walletCreationInProgress]);

  return { civicConnector: connector, walletCreationInProgress };
};
export { useAutoConnect };
