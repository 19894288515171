"use client";

import { useAccount, useSwitchChain } from "wagmi";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@civic/ui";
import baseLogo from "@/assets/base.svg";
import polygonLogo from "@/assets/polygon.svg";
import Image from "next/image";
import { baseSepolia, polygonAmoy } from "viem/chains";

const ChainSelector = () => {
  const { chainId } = useAccount();
  const { chains, switchChain } = useSwitchChain();

  const getChainLogo = (chainId: number) => {
    if (chainId === baseSepolia.id) {
      return baseLogo;
    }

    if (chainId === polygonAmoy.id) {
      return polygonLogo;
    }

    return null;
  };

  return (
    <Select
      value={`${chains.find((chain) => chain.id === chainId)?.id}`}
      onValueChange={(c) => {
        console.log('onValueChange', { c, chainId });
        if (`${chainId}` === c) return;
        console.log('switchChain', parseInt(c));
        switchChain({ chainId: parseInt(c) });
      }}
    >
      <SelectTrigger className="w-[240px]">
        <SelectValue placeholder="Select a Chain" />
      </SelectTrigger>
      <SelectContent>
        {chains.map((chain) => {
          const logo = getChainLogo(chain.id);

          return (
            // only base sepolia is supported at the moment
            <SelectItem key={chain.name} value={`${chain.id}`} disabled={chain.id !== 84532}>
              <div className="flex items-center gap-2">
                {logo ? (
                  <Image src={logo} alt={chain.name} className="h-4 w-4" />
                ) : null}{" "}
                {chain.name}
              </div>
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export { ChainSelector };
