"use client";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from "@civic/ui";
import { AlertCircle, CheckCircle } from "lucide-react";
import { useSendTransaction } from "wagmi";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect, useState } from "react";
import { Address, parseEther } from "viem";

const formSchema = z.object({
  address: z.string(),
  amount: z.string(),
});

interface SendProps {
  onComplete: () => void;
}

const Send = ({ onComplete }: SendProps) => {
  const { data, error, sendTransaction } = useSendTransaction();
  const [isLoading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address: "",
      amount: "",
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (!/^(-?)([0-9]*)\.?([0-9]*)$/.test(values.amount)) {
      form.setError("amount", {
        type: "invalid",
        message: "Invalid amount",
      });

      return;
    }

    setLoading(true);

    sendTransaction(
      {
        to: values.address as Address,
        value: parseEther(values.amount, "wei"),
      },
      {
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  useEffect(() => {
    if (data) {
      onComplete();
    }
  }, [data]);



  return (
    <div className="flex flex-col gap-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-2">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        placeholder="Recipient's address"
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        placeholder="Amount"
                        endSlot={
                          <Button size="xs" disabled={isLoading} variant="">
                            Send
                          </Button>
                        }
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>

      {data ? (
        <Alert>
          <CheckCircle className="h-4 w-4" />
          <AlertTitle>Transaction Complete</AlertTitle>
          <AlertDescription className="break-words">{data}</AlertDescription>
        </Alert>
      ) : null}

      {error ? (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="break-words">
            {error.message}
          </AlertDescription>
        </Alert>
      ) : null}
    </div>
  );
};

export { Send };
