"use client";

import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { toast } from "@civic/ui";
import { Copy } from "lucide-react";
import { useAccount } from "wagmi";


const Address = () => {
  const { address } = useAccount();

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyAddress = () => {
    if (!address) return;
    copyToClipboard(address);

    toast({
      title: "Address copied to clipboard",
      variant: "default",
    });
  };

  return (
    <div className="flex items-center justify-center p-2">
      <span className="truncate text-sm">{address}</span>{" "}
      {address && (<Copy
        className="ml-2 h-4 w-4 shrink-0 cursor-pointer text-neutral-400"
        onClick={handleCopyAddress}
      />)}
    </div>
  );
};

export { Address };
