import type { Connector } from "wagmi";

/**
 * Handles the fact, that Wagmi Connectors do not have a "ready" property.
 *
 * @param connector
 */
export const isReady = (connector: Connector) =>
  Object.prototype.hasOwnProperty.call(connector, "ready")
    ? connector.ready
    : true;
